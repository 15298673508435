$is-codecombat: true
@import "app/styles/style-flat-variables"

.nces-search-input-component
  .suggestion-wrapper
    position: relative
    .list-group
      position: absolute
      z-index: 1
      width: 100%

      .list-group-item.active
        background: $navy
        color: white
  .optional-text
    font-size: 12px
