@import "app/styles/bootstrap/variables"
@import "app/styles/mixins"
@import "app/styles/style-flat-variables"

$teal-light-1: #1FBAB4
$teal-light-2: #6AE8E3
$teal-dark: #0E4C60

#home-cn-view
  // style-flat overwrites
  h1, .text-h1
    font-family: $headline-font
    font-weight: bold
    font-size: 46px
    line-height: 62px
    letter-spacing: 2px
    margin-bottom: -6px

  h2, .text-h2
    font-family: $headline-font
    font-weight: bold
    font-size: 33px
    line-height: 62px
    letter-spacing: 2.58px

  h3, .text-h3
    font-family: $headline-font
    font-weight: bold
    font-size: 24px
    line-height: 32px
    letter-spacing: 0.52px

  h4, .text-h4
    font-family: $body-font
    font-weight: bold
    font-size: 24px
    line-height: 40px
    letter-spacing: 0.56px

  h5, .text-h5
    font-family: $body-font
    font-size: 24px
    line-height: 30px
    letter-spacing: 0.48px
    font-weight: normal

  p, .text-p
    font-family: $body-font
    font-size: 18px
    font-weight: 300
    letter-spacing: 0.75px
    line-height: 26px

  .game-based-blurb .text-navy
    font-size: 20px

  .btn-primary, .btn-navy, .btn-teal
    background-color: $teal
    border-radius: 4px
    color: $gray
    text-shadow: unset
    text-transform: uppercase
    font-weight: bold
    letter-spacing: 0.71px
    line-height: 24px
    width: 247px
    &:hover
      background-color: #2DCEC8
      transition: background-color .35s

  .btn-primary-alt, .btn-navy-alt, .btn-teal-alt
    background-color: transparent
    border: 2px solid $teal
    color: $teal
    border-radius: 4px
    text-shadow: unset
    text-transform: uppercase
    font-weight: bold
    letter-spacing: 0.71px
    line-height: 24px
    width: 247px
    &:hover
      background-color: $teal
      box-shadow: unset
      color: $gray
      transition: color .35s, background-color .35s, box-shadow .35s

  overflow: hidden

  // TODO: pull out common pieces, such as carousel controls

  #site-content-area
    .row
      padding: 62px 0px
    .row-dark
      background: linear-gradient(118.13deg, #0E4C60 0%, #20572B 100%)
    .width-container
      max-width: 1170px
      float: unset
      margin: 0 auto

    #jumbotron-container-fluid
      background-image: url("/images/pages/home/newbackground.webp")
      background-size: cover
      background-repeat: no-repeat
      background-position: center center
      height: 627px
      width: 100%
      margin: auto
      h1
        margin: 54px 64px 30px
      .button-section
        margin-left: 64px
        width: 250px
        text-transform: uppercase
        .btn-primary
          background-color: $gold
          &:hover
            background-color: #FDD147
        .btn-primary-alt
          border-color: $gold
          color: $gold
          &:hover
            background-color: $gold
            color: $gray

      .aiyouth
        text-align: right
        .gradient-title
          font-family: SourceHanSansCN-Bold
          font-weight: 700
          background: linear-gradient(to right, rgb(57,160,255), rgb(0,223,213))
          -webkit-background-clip: text
          -webkit-text-fill-color: transparent
          margin-bottom: 14px
        .subtitle
          font-family: SourceHanSansCN-Medium
          margin-right: 70px
          font-size: 30px
          color: white
        .sub2title
          font-family: SourceHanSansCN-Medium
          margin-right: 70px
          font-size: 22px
          color: white

        .aiyouth-btn
          margin-right: 70px
          background-color: $gold
          margin-top: 42px

    #game-based
      background-color: #F7F9F9
      .row
        padding: 40px 0 25px 0
      .tile-shadow
        background-color: #FFFFFF
        box-shadow: 2px 2px 6px 0 rgba(155,155,155,0.51)
        padding: 37px 40px
        height: 100%
        @media (max-width: $screen-lg-min)
          margin-bottom: 15px
        p
          font-size: 20px
          line-height: 28px
          letter-spacing: 0.83px
          min-height: 170px
          margin: 0 0 25px
        img
          max-height: 275px
          @media (max-width: $screen-md-min)
            width: 390px
          @media (max-width: $screen-xs-min)
            margin: auto
            width: 100%
        h2
          line-height: 32px
          letter-spacing: 0.66px
          margin: 27px auto 10px
        h3
          margin: 10px auto 15px

    #classroom-in-box-row
      padding: 62px 40px
      .row
        padding: 0
        &.signup_btn
          margin-top: 45px
          text-align: center

      h2, .text-p
        color: white
        margin-bottom: 0px
        @media (max-width: $screen-lg-min)
          padding-top: 20px
      .video-iframe
        text-align: left
        @media (min-width: $screen-lg-min)
          padding-top: 18px
        @media (max-width: $screen-lg-min)
          text-align: center
      img
        position: relative
        top: -15px

      ul
        list-style: none

      li
        text-indent: -20px
        margin-bottom: 15px
        &::before
          content: "•     "
          color: $teal
          font-size: 30px

      .text-p
        font-size: 24px
        letter-spacing: 0.48px
        line-height: 30px

    .open-video-btn.video-text
      color: inherit
      text-decoration: underline
      font-style: italic

    .teacher-screenshots
      padding: 10px
      margin-bottom: 40px
      .label
        color: black
        display: block
      .screenshots
        text-align: center
        .screenshot-grid
          img
            display: inline-block
            margin: 6.5px
            width: 300px
            border-radius: 4px

    #creativity-rigor
      background-color: #EAEEEE
      .row
        padding-top: 40px
      img
        width: 100%
        @media (max-width: $screen-lg-min)
          height: unset

      .tile-shadow
        background-color: white
        box-shadow: 2px 2px 6px 0 rgba(155,155,155,0.67)
        padding: 19px
        @media (max-width: $screen-lg-min)
          height: 100%
          margin-bottom: 15px
          .request-a-quote
            margin-top: -15px
        h3
          margin: 8px auto 10px
        p
          margin-bottom: 20px
          @media (max-width: $screen-lg-min)
            margin-bottom: 35px

    #built-for-teachers
      padding: 62px
      @media (max-width: $screen-lg-min)
        padding: 62px 30px
      .row
        padding: 18px 0 0
      .for-teachers-blurb p
        color: white
        margin-bottom: 40px
      h3
        padding-bottom: 25px
      #built-for-teachers-carousel
        border-radius: 14px
        background-color: #FFFFFF
        box-shadow: inset 2px 2px 6px 0 rgba(31,186,180,0.5), 4px 4px 6px 0 #0E4C60
        min-height: 425px
        padding-bottom: 42px
        padding-top: 31px
        @media (max-width: $screen-lg-min)
          height: unset
        .carousel-inner .item .row div
          float: none
          display: inline-block
          vertical-align: middle
          &.text-part-carousel
            padding-top: 8px
            vertical-align: top
        .carousel-control
          background-image: unset
          width: 60px
          img
            position: absolute
            margin: auto
            top: 0
            right: 0
            bottom: 0
            left: 0
            height: 25px
        p.padding-sm
          padding: 0 2em
        .carousel-indicators
          bottom: 0
          li
            background-color: #9B9B9B
            margin: auto 2px
            width: 8px
            height: 8px
          li.active
            background-color: $teal
            margin: auto 2px
            width: 8px
            height: 8px
        img.computer
          padding-left: 40px
          width: 100%
          @media (max-width: $screen-lg-min)
            width: 400px
            padding: 0px 0px 20px 0px

    #for-teachers, #for-leaders
      background-color: #F7F9F9
      .first_row
        padding: 40px 0 0 0
        @media (min-width: $screen-lg-min)
          padding: 40px 0
      .second_row
        padding: 0 0 45px 0
      .tile-shadow
        background-color: #FFFFFF
        box-shadow: 2px 2px 6px 0 rgba(155,155,155,0.51)
        padding: 37px 40px 5px
        height: 315px
        img
          height: 98px
        h3
          margin: 10px auto 15px

    #teachers-love-codecombat
      padding: 45px 0 31px 0
      .row
        padding: 59px 0
      p.mcrel-blurb
        color: white
        font-style: italic
        margin-bottom: 0
      // Let's us center column
      .width-320
        max-width: 320px
        display: inline-block

    #quotes
      background-color: $navy
      .row
        padding: 0
      img
        margin: 0 62px 62px 62px
        height: 42px
        width: 40px
      #quotes-carousel
        h4.text-white
          min-height: 160px
          @media (max-width: $screen-md-min)
            font-size: 20px
            min-height: 280px
        img
          margin: 30px
          height: 93px
          width: 93px
        h4
          margin: auto 168px
          &.wider
            margin: auto 84px
        .thumbnail
          width: 500px
          display: inline-block
          text-align: left
          border: unset
          background-color: unset
        .carousel-control
          background-image: unset
          width: 60px
          &.left
            img
              margin-left: 0
          img
            height: 25px
            width: 30px
            margin-top: 100px
        .carousel-indicators
          bottom: -50px
          li
            background-color: $teal
            border: unset
            margin: auto 2px
            width: 8px
            height: 8px
          li.active
            background-color: white
            border: unset
            margin: auto 2px
            width: 8px
            height: 8px
        .quote-user-info
          display: inline-grid
          text-align: left

    #century-skills
      background-color: #F7F9F9
      padding: 62px
      .row
        padding: 0
      p
        color: white
      h5
        font-weight: normal
      #century-skills-carousel
        .carousel-control
          background-image: inherit
          width: 60px
          img
            position: absolute
            margin: auto
            top: 60px
            right: 0
            bottom: 0
            left: 0
            height: 25px
        .carousel-indicators
          bottom: -5px
          li
            background-color: white
            border: unset
            margin: auto 2px
            width: 8px
            height: 8px
          li.active
            background-color: #FF6978
            border: unset
            margin: auto 2px
            width: 8px
            height: 8px
        .row.carousel-body
          padding: 0px
        .carousel-body
          background-color: $navy
          box-shadow: 2px 2px 6px 0 rgba(155,155,155,0.5)
          margin: unset
          .col-lg-8
            padding: unset
          .col-lg-4
            padding: 60px 60px 60px 40px
          .quote
            background-size: cover
            padding-top: 22px
            height: 419px
            h4
              margin: 20px auto
              @media (max-width: $screen-lg-min)
                font-size: 1.2em
            h3
              @media (max-width: $screen-lg-min)
                font-size: 1.2em
            img
              width: 42px
              height: 40px
          .quote-1
            background-image: url("https://assets.koudashijie.com/images/home/century_carousel1.jpg")
            background-position: right
            @media (min-width: $screen-sm-min)
              h4.text-white
                width: 440px
              *
                transform: translate(-100px, 0)
          .quote-2
            background-image: url("/images/pages/home/century_carousel2.png")
            background-position: right
            @media (min-width: $screen-sm-min)
              h4.text-white
                width: 415px
              *
                transform: translate(-100px, 0)
          .quote-3
            background-image: url("/images/pages/home/century_carousel3.png")
            background-position: left
            @media (min-width: $screen-sm-min)
              h4.text-white
                width: 415px
              *
                transform: translate(130px, -12.5px)
          .quote-4
            background-image: url("/images/pages/home/century_carousel4.png")
            @media (min-width: $screen-sm-min)
              h4.text-white
                width: 325px
              *
                transform: translate(-160px, -10px)

    #global
      margin: 0 auto
      .row
        padding: 52px 15px 0
      img
        height: 82px
      h2.text-gold
        margin-top: 14px
        margin-bottom: -14px
      h5
        font-weight: normal
      .col-xs-6
        padding: 10px 15px

    #featured-partners
      .row
        padding: 0
      div.container.inner-container
        padding-top: 62px

      @media (min-width: $screen-lg-min)
        background: linear-gradient(90deg, #F7F9F9 50%, #EAEEEE 50%)
      .row:last-child
        padding-bottom: 62px
      padding: 0
      .inner-container
        max-width: 1280px
        @media (min-width: $screen-lg-min)
          background: linear-gradient(90deg, #F7F9F9 41.7%, #EAEEEE 41.7%)

      .pink-bar
        margin-top: -14px
        padding-bottom: 31px

      @media (min-width: $screen-xs-min)
        .tile-row
          display: inline-block

      @media (min-width: $screen-lg-min)
        .featured-section .tile-row
          transform: translateX(15px)
        .partners-section
          transform: translateX(37px)
      .partners-section
        .col-sm-12 a img
          margin-top: -22px
      .tile-shadow
        background-color: #FFFFFF
        box-shadow: 2px 2px 6px 0 rgba(155,155,155,0.51)
        padding: 0px
        margin: 2px 2px -6px
        @media (max-width: $screen-sm-min)
          margin: 20px
          &:last-child
            margin-bottom: -7px
        height: 190px
        width: 190px
        img
          width: 100%
        p
          color: #9B9B9B
          font-family: $body-font
          font-size: 14px
          letter-spacing: 0.58px
          line-height: 19px
        .featured-partners-tile-logo
          position: absolute
          margin: auto
          top: 0
          right: 0
          bottom: 0
          left: 0
        .logo-pcmag
          height: 100px
          width: 100px
        .logo-sohu
          height: 33px
          width: 141px
        .logo-apcsp
          height: 100px
          width: 100px
        .logo-codie
          height: 55px
          width: 156px
        .logo-cse-picks
          height: 110px
          width: 110px
        .logo-sina
          height: 37px
          width: 145px
        .logo-jiemodui
          height: 48px
          width: 120px
        .logo-code-org
          height: 80px
          width: 81px
        .logo-csforall
          height: 60px
          width: 133px
        .logo-amazon-future-engineer
          height: 35px
          width: 166px
        .logo-blurb
          color: #9B9B9B
          font-family: $body-font
          font-size: 14px
          font-weight: 200
          letter-spacing: 0.58px
          line-height: 19px
          position: absolute
          top: 145px
          width: 100%
  #top-banner
    background-color: $teal-light-2
    padding: 10px 0px
    text-align: center
    font-size: 18px
    font-style: normal
    line-height: 20px
    .row
      max-width: 1170px
      float: unset
      margin: 0 auto
      padding: 0px
    a
      margin-left: 10px
      font-weight: 700
      color: #000


  .floatCS
    position: fixed
    bottom: 0
    padding-top: 5px
    right: 50px
    width: 60px
    border-radius: 8px
    background-color: #0085dd
    display: flex
    flex-direction: column
    align-items: center
    color: white
    font-size: 10px
    cursor: pointer
    &:hover .qr
        display: block
    .qr
      width: 100px
      position: absolute
      display: none
      top: -120px
    .icon
      width: 4em
    .text
      text-align: center

body[lang='ru'], body[lang='uk'], body[lang='bg'], body[lang^='mk'], body[lang='sr'], body[lang='pl'], body[lang='lt'], body[lang='sl'], body[lang='tr'], body[lang='vi']
  #home-view
    // Google Fonts version of Arvo only has Latin glyphs, not Cyrillic
    // TODO: figure out font fallbacks for other languages not covered by Arvo
    //$headline-font: 'Open Sans', serif  // Doesn't work
    h1, h2, h3, h4, h5, .text-h1, .text-h2, .text-h3, .text-h4, .text-h5
      font-family: 'Open Sans', serif
