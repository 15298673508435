@import "app/styles/bootstrap/variables";

$dark-grey: #0a2239;
$dark-grey-2: #111928;
$light-grey: #6b7280;
$light-grey-2: #c7c7c7;
$purple: #7a65fc;
$purple-2: #9487ff;
$middle-purple: #d5d5fc;
$light-purple: #e9e8fd;
$light-background: #F9F9FF;

$teal-light-1: #1FBAB4;
$teal-light-2: #6AE8E3;

$main-font-family: "Plus Jakarta Sans";

$small-screen-height: 768px;

%font-64-80 {
  font-size: 64px;
  line-height: 80px;

  @media screen and (max-width: $screen-sm) {
    font-size: 40px;
    line-height: 50px;
  }
}

%font-40 {
  font-size: 40px;
  line-height: 50px;

  @media screen and (max-width: $screen-sm) {
    font-size: 30px;
    line-height: 38px;
  }
}

%font-44 {
  font-size: 44px;
  line-height: 125%;

  @media screen and (max-width: $screen-sm) {
    font-size: 32px;
  }
}

%font-36 {
  font-size: 36px;
  line-height: 125%;

  @media screen and (max-width: $screen-sm) {
    font-size: 28px;
  }
}

%font-32-46 {
  font-size: 32px;
  line-height: 46px;

  @media screen and (max-width: $screen-sm) {
    font-size: 22px;
    line-height: 28px;
  }
}

%font-28 {
  font-size: 28px;
  line-height: 125%;

  @media screen and (max-width: $screen-sm) {
    font-size: 20px;
  }
}

%font-24-30 {
  font-size: 24px;
  line-height: 30px;

  @media screen and (max-width: $screen-sm) {
    font-size: 18px;
    line-height: 24px;
  }
}

%font-24-34 {
  font-size: 24px;
  line-height: 34px;

  @media screen and (max-width: $screen-sm) {
    font-size: 18px;
    line-height: 28px;
  }
}

%font-20 {
  font-size: 20px;
  line-height: 28px;

  @media screen and (max-width: $screen-sm) {
    font-size: 16px;
    line-height: 24px;
  }
}

%font-18-24 {
  font-size: 18px;
  line-height: 24px;

  @media screen and (max-width: $screen-sm) {
    font-size: 14px;
    line-height: 20px;
  }
}

%font-16 {
  font-size: 16px;
  line-height: 24px;

  @media screen and (max-width: $screen-sm) {
    font-size: 14px;
    line-height: 20px;
  }
}

%font-14 {
  font-size: 14px;
  line-height: 20px;

  @media screen and (max-width: $screen-sm) {
    font-size: 12px;
    line-height: 18px;
  }
}

%gap-70 {
  gap: 70px;

  @media screen and (max-width: $screen-sm) {
    gap: 35px;
  }
}

%height-350 {
  height: 350px;

  @media screen and (max-width: $screen-sm) {
    height: auto;
  }
}

%padding-vertical-140 {
  padding-top: 140px;
  padding-bottom: 140px;

  @media screen and (max-width: $screen-sm) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

%frontend-page {
  display: flex;
  gap: 80px;
  flex-direction: column;

  &,
  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  button,
  div {
    font-family: $main-font-family;
    font-variant: normal;
  }

  .text-h1 {
    @extend %font-32-46;
    color: $dark-grey;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    margin: 80px auto 40px auto;
  }

  .text-h2 {
    text-align: center;
    margin: 40px auto;
  }

  p.text-24 {
    @extend %font-24-34;
    color: $light-grey;
  }

  p.text-14 {
    @extend %font-14;
  }

  .text-highlight {
    color: $purple;
  }
}
