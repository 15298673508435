$is-codecombat: true;

@import "app/styles/component_variables.scss";

.mixed-color-label {
  &__normal {
    color: var(--color-dark-grey);
    &.inherit {
      color: inherit;
    }
  }

  &__highlight {
    color: var(--color-primary);
  }

  a {
    text-decoration: underline;
  }
}
