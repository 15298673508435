@import "app/styles/mixins"
@import "app/styles/bootstrap/variables"

.spell-translation-view
  position: absolute
  z-index: 9001
  max-width: 400px
  pre
    margin-bottom: 0
    code
      white-space: nowrap

