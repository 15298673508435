$is-codecombat: true;

@import "ozaria/site/styles/common/variables.scss";
@import "ozaria/site/components/teacher-dashboard/common/_dusk-button";

#test-student-modal {
  font-size: 20px;
  padding: 20px;

  .small-p {
    font-size: 16px;
    margin-top: 5px;
  }

  .small-font {
    font-size: 14px;
    margin-top: 5px;
  }

  .bold-span {
    font-weight: bold;
  }

  .dusk-btn {
    display: inline-block;
  }
}
