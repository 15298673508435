@import "app/styles/ozaria/_ozaria-style-params.scss";
@import "app/styles/style-flat-variables.sass";

@if not $is-codecombat {
  $title-font-style: 'Arvo', serif !global;
  $body-font-style: 'Open Sans', sans-serif !global;
  $label-font-style: 'Work Sans', sans-serif !global;
  $code-font-style: 'Roboto Mono', monospace !global;

  // Ozaria Chrome
  $chromeTopPadding: 8vh !global;
  $chromeRightPadding: 6vw !global;
  $chromeBottomPadding: 4vh !global;
  $chromeLeftPadding: 2vw !global;

  // For the single progress table
  $tooltip-spacing: 200px !global;
  $anti-tooltip-spacing: -200px !global;
}
// Used in the signup modals
$authGray: #a4a4a4 !global;

// Following Ozaria marketing guide
$white: #ffffff !global;
$mist: #eeeced !global;
$moon: #f7d047 !global;
$goldenlight: #d1b147 !global;
$sun: #ff8600 !global;
$dawn: #532e4b !global;
$dusk: #5db9ac !global;
$dusk-dark: #379b8d !global;
$fog: #6bd7ef !global;
$twilight: #476fb1 !global;
$eve: #2d5859 !global;
$pitch: #131b25 !global;
$haze: #6d8594 !global;
$acodus-glow: #40f3e4 !global;
$grey-6-dark: #545B64 !global;


// Used in Single class table
$studentNameWidth: 260px !global;