$is-codecombat: true;

.announcement-menu {

  font-size: 18px;
  font-weight: 500;
  line-height: 25px;

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 10px;
    margin-left: 15px;
    margin-right: 15px;
    text-align: left;
    cursor: pointer;
  }

  .read {
    opacity: 50%;
  }

  li.split {
    margin: 15px;
    border-top: 1px solid #aaa;
  }

  li.more {
    margin: 10px;
    margin-bottom: 15px;
    cursor: pointer;
    color: #333;
  }
}
